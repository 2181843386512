import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Close from 'public/shared-icons/Close.svg';
import SolarFilter from 'public/shared-icons/solar_filter-outline.svg';
import { IComponentData } from 'types/new-app-d';

import { iconMap } from '@/lib/helper';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DebouncedInput } from '@/components/debouncedSearch/DebouncedSearch';

export const columns: ColumnDef<IComponentData>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="bg-canvas-background-primary"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="bg-canvas-background-primary "
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'display_name',
    header: 'Component Display Name',
    cell: ({ row }) => {
      const type = row.original.type as string;
      return (
        <div className="flex gap-2 capitalize">
          <Image
            src={iconMap[type] ?? iconMap['default']}
            alt="component type icon"
            height="20"
            width="20"
          />
          {row.getValue('display_name')}
        </div>
      );
    },
  },
];

export const CompTable = ({
  data,
  selectedComponent,
  setSelectedComponent,
}: {
  data: IComponentData[];
  selectedComponent: IComponentData[];
  setSelectedComponent: React.Dispatch<React.SetStateAction<IComponentData[]>>;
}) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      columnFilters,
      rowSelection,
      globalFilter,
    },
  });

  useEffect(() => {
    const initialSelection: { [key: string]: boolean } = {};

    data.forEach((item, index) => {
      if (
        selectedComponent.some(
          (selected) => selected.component_name === item.component_name
        )
      ) {
        initialSelection[index] = true;
      }
    });

    setRowSelection(initialSelection);
  }, []);

  useEffect(() => {
    const rowsSelected = Object.keys(rowSelection);
    const selectedIds = rowsSelected.map((index) => data[Number(index)]);
    setSelectedComponent(selectedIds);
  }, [rowSelection]);

  return (
    <div className="w-full">
      <div className="flex items-center">
        <div className="mb-5 flex w-full items-center gap-2 rounded-[2px] border border-form-control-border-rest p-3 shadow">
          <Image src={SolarFilter} alt="filter" height="16" width="16" />
          <DebouncedInput
            placeholder="Filter by name, service type, or keyword…"
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="leading-sm w-full bg-transparent text-sm outline-none"
          />
          {globalFilter.length > 0 && (
            <Image
              src={Close}
              alt="filter"
              height="11.3"
              width="11.3"
              className="h-3 w-3"
              onClick={() => setGlobalFilter('')}
            />
          )}
        </div>
      </div>
      <div className="h-[459px] overflow-auto rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-line-primary">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="font-semibold text-white"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className="border-line-secondary text-body-subtle"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="[&>div]:last:!text-left [&>div]:last:!font-normal"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
