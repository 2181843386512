/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import SolarQuestionIcon from 'public/shared-icons/solar_question-circle-outline.svg';
import { IComponentData } from 'types/new-app-d';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { DetailsPageSpinner } from '@/components/spinner/details-page-spinner';

import { CompTable } from './CompTable';
import NoWorkflowFound from './NoWorkflowFound';
import { SdkPopover } from './SdkPopover';

export const AssignableComp = ({
  open,
  setOpen,
  addedComponent,
  setAddedComponent,
  addComponentFromDetail,
  componentArr,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  addedComponent: IComponentData[];
  setAddedComponent: React.Dispatch<React.SetStateAction<IComponentData[]>>;
  addComponentFromDetail?: (arr: IComponentData[]) => void;
  componentArr?: IComponentData[];
}) => {
  const [loading, setLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] =
    useState<IComponentData[]>(addedComponent);
  const [filteredCompArr, setFilteredCompArr] = useState<IComponentData[]>([]);
  const { messages } = useLanguage();

  const langData = messages?.ApplicationForm;

  const handleCloseModal = () => {
    setIsPopoverOpen(false);
    setOpen(false);
  };

  const btnDisabled = selectedComponent.length > 0 ? false : true;

  const filterOutSelectedComp = (data: IComponentData[]) => {
    const idsToFilter = addedComponent?.map((item) => item?.component_name);
    const filterData = data.filter(
      (item) => !idsToFilter.includes(item?.component_name)
    );

    setFilteredCompArr(filterData);
  };

  const handleAddComponent = async () => {
    setIsPopoverOpen(false);
    setOpen(false);
    setAddedComponent((prev) => [...prev, ...selectedComponent]);
    if (addComponentFromDetail) {
      addComponentFromDetail(selectedComponent);
    }
  };

  const fetchAllComponents = async () => {
    if (componentArr && componentArr.length > 0) {
      return;
    } else {
      try {
        setLoading(true);
        await request.put({ endpoint: 'discovery' });

        const data = await request.get({
          endpoint: `components?app_associable=true`,
        });

        filterOutSelectedComp(data[0]?.components);

        return data[0];
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAllComponents();
  }, []);

  useEffect(() => {
    filterOutSelectedComp(filteredCompArr);
  }, [addedComponent]);

  useEffect(() => {
    if (componentArr) {
      filterOutSelectedComp(componentArr);
    }
  }, [componentArr]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="max-w-[976px] border-link-active bg-gradient-sky-dark p-8"
        aria-describedby={undefined}
        onInteractOutside={(e) => {
          if (isPopoverOpen) {
            e.preventDefault();
          }
        }}
      >
        <DialogHeader>
          <DialogTitle className="border-b border-line-primary pb-5 text-2xl">
            {langData?.assignable_components}
          </DialogTitle>
        </DialogHeader>
        <div className="h-[566px]">
          {loading ? (
            <div className="flex h-full w-full flex-col items-center justify-center">
              <div>
                <DetailsPageSpinner />
              </div>
              <p className="mt-4 text-sm">{langData?.discovering_components}</p>
              <p className="mt-2 text-xs text-form-input-disabled">
                {messages?.General?.please_wait}..
              </p>
            </div>
          ) : filteredCompArr.length > 0 ? (
            <div className="flex h-full flex-col justify-between">
              <CompTable
                data={filteredCompArr}
                selectedComponent={selectedComponent}
                setSelectedComponent={setSelectedComponent}
              />

              <div className="flex items-center justify-between border-t border-t-line-primary pt-5">
                <div className="flex gap-3">
                  <Button
                    size="xs"
                    disabled={btnDisabled}
                    onClick={handleAddComponent}
                  >
                    {langData?.add_selection}
                  </Button>
                  <Button size="xs" disabled variant="outline">
                    <span className="mr-2">{langData?.define_component}</span>
                    <span className="-ml-0.5 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm uppercase text-[#07181E]">
                      {messages?.General?.wip}
                    </span>
                  </Button>
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={handleCloseModal}
                  >
                    {messages?.General?.cancel}
                  </Button>
                </div>
                <div className="flex gap-1 text-xs">
                  <div className="flex gap-1 text-body-subtle">
                    <Image
                      src={SolarQuestionIcon}
                      alt="question icon"
                      className="h-4 w-4"
                    />
                    {langData?.cant_find_component}?
                  </div>

                  <SdkPopover
                    isPopoverOpen={isPopoverOpen}
                    setIsPopoverOpen={setIsPopoverOpen}
                    fetchAllComponents={fetchAllComponents}
                    langDataText={langData?.learn_to_deploy_sdk}
                  />
                </div>
              </div>
            </div>
          ) : (
            <NoWorkflowFound
              setOpen={setOpen}
              fetchAllComponents={fetchAllComponents}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
