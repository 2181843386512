import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

import SdkContent from './SdkContent';

export const SdkPopover = ({
  isPopoverOpen,
  setIsPopoverOpen,
  fetchAllComponents,
  langDataText,
}: {
  isPopoverOpen: boolean;
  setIsPopoverOpen: (open: boolean) => void;
  fetchAllComponents: any;
  langDataText: any;
}) => {
  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger>
        <div className="cursor-pointer text-link-active">{langDataText}</div>
      </PopoverTrigger>
      <PopoverContent className="relative w-80 bg-canvas-background-secondary p-6">
        <div className="absolute -bottom-[17px] left-[78%] h-4 w-4 -translate-y-1/2 rotate-45 transform border-b border-r border-line-secondary bg-canvas-background-secondary"></div>
        <SdkContent
          setOpen={setIsPopoverOpen}
          fetchAllComponents={fetchAllComponents}
          closePopover
        />
      </PopoverContent>
    </Popover>
  );
};
